<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow3.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                    Energy-efficient reflow system for maximum throughput
                  </h2>
                  <br />
                  <h5>
                  The HOTFLOW 3e is a full-fledged reflow soldering system from the HOTFLOW family and delivers best soldering results due to its thermal performance, its good cross profile and best zone separation. The "e" stands for "efficency". <br>
                  The product development thus already emphasized the special economic efficiency of the system in the product designation. For the design, Ersa drew on the know-how and wealth of experience of the Reflow success story.
                  </h5>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/divhotflow4.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <br /><br /><br /><br />
                <div class="mt-4">
                  <h2>Technology Highlights</h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Productivity increase through double transport
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Optimized energy transfer, minimized ΔT and increased zone separation</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Internal cooling</h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Removable heating modules top and bottom
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Low energy consumption</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Extreme low-mass center support</h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                       ERSASOFT process data recording

                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>User-friendly ERSASOFT machine control system</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Auto profiler for rapid offline profiling</h5>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br />
            <div>
             
              <br /><br />
              <!-- video -->
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-xl-6">
                          <!-- 16:9 aspect ratio -->
                          <div class="ratio ratio-21x9">
                            <iframe
                              style="width: 500px; height: 250px;"
                              title="YouToube Video"
                              src="https://www.youtube.com/embed/_7KolIRmX0Q"  
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>

                        <div class="col-xl-6">
                          <div class="ratio ratio-21x9">
                            <iframe
                              style="width: 500px; height: 250px;"
                              title="YouToube Video"
                              src="https://www.youtube.com/embed/iFKvei57rLc" 
                              allowfullscreen=""
                            ></iframe>
                          </div>
                          
                        </div>
                        <!-- end col -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>